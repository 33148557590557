const {Stripe, location} = window

export function openStripe (stripeKey, sessionId) {
  var stripe = Stripe(stripeKey);

  stripe.redirectToCheckout({
    sessionId: sessionId
  }).then(function (result) {
    if (result.error) {
      alert(result.error.message)
      location.reload()
    }
  });
}
